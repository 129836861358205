/* istanbul ignore file */
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import { Box, Grid } from "@material-ui/core";

import GiantSearchBar from "../GiantSearchBar/GiantSearchBar";
import Hero from "../Hero/Hero";
import MiddleCards from "./MiddleCards";
import OurData from "./OurData";
import FootCards from "./FootCards";

import classes from "./HomePage.module.css";

import ourDataImage from "../../assets/images/ourdata.jpg";
import { clearLocalStorage, LS_IN_CART } from "../../utils/LocalStorageUtils";

const bgImgStyle = {
  backgroundImage: `url(${ourDataImage})`,
  backgroundSize: "cover",
  backgroundPosition: "top center",
};

const HomePageContainer = (props) => {
  const { ldClient, honeycomb } = props;

  const [HCLog, _setHCLog] = useState({});

  const HCLogRef = useRef(HCLog);
  const setHCLog = (data) => {
    HCLogRef.current = data;
    _setHCLog(data);
  };

  const heroRef = useRef();

  const handleScroll = () => {
    if (heroRef.current) {
      const heroRect = heroRef.current.getBoundingClientRect();
      if (!HCLogRef.current.scroll) {
        setHCLog({ ...HCLogRef.current, scroll: true });
        honeycomb.sendUiInteractionSpan("homepage-scrolling");
      }
      if (heroRect.top <= 0 && !HCLogRef.current.heroTop) {
        setHCLog({ ...HCLogRef.current, heroTop: true });
        honeycomb.sendUiInteractionSpan("homepage-hero-top-to-top");
      }

      if (heroRect.bottom <= 0 && !HCLogRef.current.heroBottom) {
        setHCLog({ ...HCLogRef.current, heroBottom: true });
        honeycomb.sendUiInteractionSpan("homepage-hero-bottom-to-top");
      }
    }
  };

  /* istanbul ignore next */
  useEffect(() => {
    // on home page loading - clear local storage except these listed
    clearLocalStorage([LS_IN_CART]);

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Box ref={heroRef}>
        <Hero />
      </Box>

      <Box className={classes.mainBox}>
        <GiantSearchBar honeycomb={honeycomb} ldClient={ldClient} />
        <MiddleCards honeycomb={honeycomb} />
      </Box>
      <div style={bgImgStyle}>
        <Box className={classes.mainBox} style={{ paddingBottom: 60 }}>
          <Grid container spacing={6} style={{ padding: "10px 24px 0px" }}>
            <Grid item md={12} xs={12}>
              <OurData honeycomb={honeycomb} />
              <FootCards honeycomb={honeycomb} />
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
};

export default HomePageContainer;

HomePageContainer.defaultProps = {
  ldClient: null,
  honeycomb: null,
};

HomePageContainer.propTypes = {
  ldClient: PropTypes.shape(),
  honeycomb: PropTypes.shape(),
};
